import React from "react";
import Account from "../components/Account";
import Game from "../components/Game";

export default function GamePage(props) {
  return (
    <div>
      <div className="container">
        <div className="row mb-3">
          <Account />
        </div>
        <div className="row mb-3">
          <Game />
        </div>
      </div>
    </div>
  );
}

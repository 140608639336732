import React from "react";
import { displayNear, formatTimer, Loading } from "../data/utils";
import Timer from "react-compound-timer";
import Bets from "./Bets";

export default function PreviousGame(props) {
  const game = props.game;

  const totalDuration =
    game.lastBet.timestamp + game.timerDuration - game.startTimestamp;

  return (
    <>
      <div className="card mb-2">
        {game.loading ? (
          <div className="card-body">{Loading} loading...</div>
        ) : (
          <div className="card-body">
            <h2>Game #{game.index}</h2>
            <hr />
            <div>
              <h5>
                <span className="text-secondary">Winner: </span>
                {game.lastBet.accountId}
              </h5>
            </div>
            <div>
              <h5>
                <span className="text-secondary">Bank: </span>
                {displayNear(game.bank)}
              </h5>
            </div>
            <div>
              <h5>
                <span className="text-secondary">Total duration: </span>
                <Timer
                  initialTime={totalDuration}
                  startImmediately={false}
                  direction="backward"
                  timeToUpdate={1000}
                  lastUnit="d"
                >
                  {formatTimer}
                </Timer>
              </h5>
            </div>
            <Bets game={game} />
          </div>
        )}
      </div>
    </>
  );
}

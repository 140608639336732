import React, { useEffect, useState } from "react";
import { useAccount } from "../data/account";
import {
  displayNear,
  formatTimer,
  Loading,
  OneNear,
  tokenStorageDeposit,
} from "../data/utils";
import { useCurrentGame } from "../data/currentGame";
import Timer from "react-compound-timer";
import { NearConfig, TGas } from "../data/near";
import Bets from "./Bets";
import { useToken } from "../data/token";
import * as nearAPI from "near-api-js";

export default function Game(props) {
  const account = useAccount();
  const game = useCurrentGame();
  const rektToken = useToken(NearConfig.rektTokenAccountId);

  const [loading, setLoading] = useState(false);
  const [bet, setBet] = useState(null);

  useEffect(() => {
    if (game && !game.loading) {
      setBet(game.bet());
    }
  }, [game]);

  const remainingDuration = game && !game.loading && game.remainingDuration;

  const makeBet = async (e, bet) => {
    e.preventDefault();
    setLoading(true);
    const actions = [];

    if (!(await rektToken.contract.isRegistered(account, account.accountId))) {
      actions.push([
        NearConfig.rektTokenAccountId,
        nearAPI.transactions.functionCall(
          "storage_deposit",
          {
            account_id: account.accountId,
            registration_only: true,
          },
          TGas.mul(5).toFixed(0),
          (await tokenStorageDeposit(NearConfig.rektTokenAccountId)).toFixed(0)
        ),
      ]);
    }

    actions.push([
      NearConfig.contractName,
      nearAPI.transactions.functionCall(
        "bet",
        {},
        TGas.mul(200).toFixed(0),
        bet.toFixed(0)
      ),
    ]);

    await account.near.sendTransactions(actions);
  };

  const isWinner =
    game && game.lastBet && game.lastBet.accountId === account.accountId;

  return (
    <>
      <div className="card mb-2">
        {game.loading ? (
          <div className="card-body">{Loading} loading...</div>
        ) : (
          <div className="card-body">
            <h2>Current game #{game.index}</h2>
            <hr />
            <div>
              <h5>
                <span className="text-secondary">Bank: </span>
                {displayNear(game.bank)}
              </h5>
            </div>
            <div>
              <h5>
                <span className="text-secondary">Remaining duration: </span>
                {remainingDuration && remainingDuration > 0 ? (
                  <Timer
                    key={`${game.index}-${remainingDuration}`}
                    initialTime={remainingDuration}
                    startImmediately={game && !!game.lastBet}
                    direction="backward"
                    timeToUpdate={1000}
                    lastUnit="d"
                  >
                    {formatTimer}
                  </Timer>
                ) : (
                  "The round is over"
                )}
              </h5>
            </div>
            <Bets game={game} />
            <hr />
            {account && !account.accountId ? (
              <div>
                <h5>Sign in to bet</h5>
              </div>
            ) : isWinner ? (
              <div>
                <h5>You hold the winning bet</h5>
                {remainingDuration && remainingDuration < 0 && (
                  <div>
                    <button
                      className="btn btn-success btn-lg mt-3"
                      disabled={loading}
                      onClick={(e) => makeBet(e, OneNear)}
                    >
                      {loading && Loading}
                      You've probably won. Bet {displayNear(OneNear)} to start
                      the next round and claim the reward.
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-buttons">
                <button
                  className="btn btn-success btn-lg mt-3"
                  disabled={loading}
                  onClick={(e) => makeBet(e, bet)}
                >
                  {loading && Loading}
                  Bet {displayNear(bet)}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

import React, { useState } from "react";
import Bet from "./Bet";
import uuid from "react-uuid";

export default function Bets(props) {
  const [gkey] = useState(uuid());
  const game = props.game;
  const bets = game && game.lastBets;

  return (
    bets &&
    bets.length > 0 && (
      <div>
        <h5>
          <span className="text-secondary">
            Last {bets.length} bet
            {bets.length > 1 && "s"} out of{" "}
          </span>
          {game.numBets}
        </h5>
        <table className="table table-striped">
          <tbody>
            {[...bets].reverse().map((bet, i) => {
              return <Bet key={`${gkey}-${i}`} bet={bet} />;
            })}
          </tbody>
        </table>
      </div>
    )
  );
}

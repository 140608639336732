import { singletonHook } from "react-singleton-hook";
import { useEffect, useState } from "react";
import { useAccount } from "./account";
import { keysToCamel } from "./utils";
import Big from "big.js";

const OneMinute = 60 * 1000;
const OneHour = 60 * OneMinute;

const defaultGame = {
  loading: true,
};

const mapBet = (b) => {
  b.bet = Big(b.bet);
  b.date = new Date(b.timestamp);

  return b;
};

export const mapGame = (g) => {
  g = keysToCamel(g);

  g.lastBets = g.lastBets.map(mapBet);
  g.bank = Big(g.bank);
  g.totalSpent = Big(g.totalSpent);
  g.startDate = new Date(g.startTimestamp);
  g.startBet = Big(g.startBet);
  g.currentDate = new Date(g.currentTime);
  g.localCurrentDate = new Date();

  g.lastBet = g.numBets === 0 ? null : g.lastBets[g.lastBets.length - 1];

  g.bet = () => {
    if (g.numBets === 0) {
      return g.startBet;
    } else {
      const passedDuration = g.currentTime - g.lastBet.timestamp;
      const bonus = Big(0.01).mul(
        Math.max(Math.trunc(g.timerDuration / OneHour), 1)
      );
      return g.lastBet.bet
        .mul(
          Big(1).add(
            bonus
              .mul(Math.min(passedDuration + OneMinute, g.timerDuration))
              .div(g.timerDuration)
          )
        )
        .round(0, 0);
    }
  };

  g.remainingDuration =
    g.numBets === 0
      ? g.timerDuration
      : g.lastBet.timestamp + g.timerDuration - g.currentTime;

  return g;
};

const fetchGame = async (account) => {
  const game = mapGame(await account.near.contract.get_current_game());
  return Object.assign(game, {
    loading: false,
  });
};

const gameRefreshTimers = {};

export const useCurrentGame = singletonHook(defaultGame, () => {
  const [game, setGame] = useState(defaultGame);
  const account = useAccount();

  useEffect(() => {
    if (account && !account.loading) {
      let setupAutoRefresh = null;
      const refreshGame = async () => {
        const game = await fetchGame(account);
        setupAutoRefresh(game);
        setGame(game);
      };

      setupAutoRefresh = (game) => {
        clearInterval(gameRefreshTimers[game.index]);
        gameRefreshTimers[game.index] = setInterval(async () => {
          if (!document.hidden) {
            await refreshGame();
          }
        }, 1000);
      };

      refreshGame();
    }
  }, [account]);

  return game;
});

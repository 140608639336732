import React, { useState } from "react";
import { Loading } from "../data/utils";
import uuid from "react-uuid";
import PreviousGame from "../components/PreviousGame";
import { usePreviousGames } from "../data/previousGames";

export default function PreviousGamesPage(props) {
  const [gkey] = useState(uuid());
  const previousGames = usePreviousGames();

  return (
    <div>
      <div className="container">
        <div className="row mb-3">
          {previousGames.loading ? (
            <div className="card-body">{Loading} loading...</div>
          ) : (
            [...previousGames.games].reverse().map((game) => {
              return <PreviousGame key={`${gkey}-${game.index}`} game={game} />;
            })
          )}
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function RulesPage(props) {
  return (
    <div>
      <div className="container">
        <div className="row mb-3">
          <div className="card">
            <div className="card-body">
              <h2>Game Rules</h2>
              <hr />
              <div>
                <li>Bet to win the bank.</li>
                <li>When timer expires the last bet wins the bank.</li>
                <li>Every new bet resets the timer.</li>
                <li>Over time the size of the bet increases.</li>
                <li>
                  80% of the bet goes to the bank, 10% goes to the jackpot and
                  10% is used to buy $REKT token on REF Finance.
                </li>
                <li>
                  When rounds ends, 50% of the jackpot goes to the bank of the
                  next round. The rest 50% remains in the jackpot to be used in
                  future rounds.
                </li>
                <li>
                  The next round timer duration is usually longer than the
                  previous round.
                </li>
              </div>
              <hr />
              <h3>$REKT token</h3>
              <div>
                <li>
                  $REKT is a fungible token:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://explorer.near.org/accounts/rekt.tkn.near"
                  >
                    rekt.tkn.near
                  </a>
                </li>
                <li>
                  Every bet gets you $REKT token equal to 1000 times the bet in
                  NEAR. E.g. for 1.5 NEAR you get 1500 $REKT
                </li>
                <li>
                  Winning the round gets you 1000 times $REKT in addition to
                  NEAR. E.g. 100 NEAR bank gives you 100,000 $REKT
                </li>
                <li>Total supply of $REKT is 1,000,000,000</li>
                <li>
                  5% of $REKT is sold on Skyward (5% referral program):{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://app.skyward.finance/sale/16"
                  >
                    https://app.skyward.finance/sale/16
                  </a>
                </li>
                <li>
                  95% of $REKT is owned by the rekt-casino.near contract to be
                  distributed for bets and banks.
                </li>
                <li>
                  $REKT is listed on REF finance (but not whitelisted):{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://app.ref.finance/pool/9"
                  >
                    https://app.ref.finance/pool/9
                  </a>
                </li>
                <li>
                  10% of every bet in NEAR buys $REKT from the REF Finance pool,
                  which increases the price of $REKT within the pool.
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { displayNear } from "../data/utils";
import TimeAgo from "timeago-react";
import { IsMainnet, NearConfig } from "../data/near";
import { useAccount } from "../data/account";
import * as nearAPI from "near-api-js";
import Big from "big.js";

const cachedBalances = {};

const fetchNativeBalance = async (near, accountId) => {
  try {
    const account = new nearAPI.Account(
      near.nearConnection.connection,
      accountId
    );
    const state = await account.state();
    const balance = Big(state.amount).sub(
      Big(state.storage_usage).mul(Big(NearConfig.storageCostPerByte))
    );
    return balance;
  } catch (e) {
    console.error(e);
  }
  return Big(0);
};

export default function Bet(props) {
  const bet = props.bet;

  const accountId = bet.accountId;

  const [nativeBalance, setNativeBalance] = useState(null);

  const account = useAccount();
  useEffect(() => {
    if (account && account.near && nativeBalance === null) {
      if (!(accountId in cachedBalances)) {
        cachedBalances[accountId] = fetchNativeBalance(account.near, accountId);
      }
      cachedBalances[accountId].then(setNativeBalance);
    }
  }, [account, accountId, nativeBalance]);

  const explorerLink = IsMainnet
    ? `https://explorer.near.org/accounts/${bet.accountId}`
    : `https://explorer.testnet.near.org/accounts/${bet.accountId}`;

  return (
    <tr>
      <td className="col-6">
        <a href={explorerLink}>{bet.accountId}</a> ({displayNear(nativeBalance)}
        )
      </td>
      <td className="col-3">{displayNear(bet.bet)}</td>
      <td className="col-3">
        <TimeAgo datetime={bet.date} />
      </td>
    </tr>
  );
}

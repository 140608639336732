import { singletonHook } from "react-singleton-hook";
import { useEffect, useState } from "react";
import { useAccount } from "./account";
import { mapGame } from "./currentGame";

const defaultGames = {
  loading: true,
  games: [],
};

const fetchGames = async (account) => {
  const rawGames = await account.near.contract.get_games();
  const games = rawGames.slice(0, rawGames.length - 1).map(mapGame);
  return {
    loading: false,
    games,
  };
};

export const usePreviousGames = singletonHook(defaultGames, () => {
  const [games, setGames] = useState(defaultGames);
  const account = useAccount();

  useEffect(() => {
    if (account && !account.loading) {
      fetchGames(account).then(setGames);
    }
  }, [account]);

  return games;
});

import React, { useEffect, useState } from "react";
import { useAccount } from "../data/account";
import {
  availableNearBalance,
  bigToString,
  displayNear,
  Loading,
} from "../data/utils";
import { useToken } from "../data/token";
import { NearConfig } from "../data/near";
import Big from "big.js";

export default function Account(props) {
  const account = useAccount();

  const rektToken = useToken(NearConfig.rektTokenAccountId);
  const nearBalance = availableNearBalance(account);

  const [tokenBalance, setTokenBalance] = useState(null);

  useEffect(() => {
    if (
      account.accountId &&
      rektToken &&
      rektToken.metadata &&
      tokenBalance === null
    ) {
      rektToken.contract
        .balanceOf(account, account.accountId)
        .then(setTokenBalance)
        .catch(() => setTokenBalance(false));
    }
  }, [account, tokenBalance, rektToken]);

  return account && account.accountId ? (
    <>
      <div className="card mb-2">
        {account.loading ? (
          <div className="card-body">{Loading} loading...</div>
        ) : (
          <div className="card-body">
            <h5>
              <span className="text-secondary">Your available balances </span>
            </h5>

            <h5>
              <li>{displayNear(nearBalance)}</li>
            </h5>
            {tokenBalance && (
              <h5>
                <li>
                  {bigToString(tokenBalance.div(Big(10).pow(18)))}{" "}
                  <span className="text-secondary">REKT</span>
                </li>
              </h5>
            )}
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );
}
